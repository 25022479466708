







import { defineComponent } from '@vue/composition-api';
import CircledQuestionMark from './icons/CircledQuestionMark.vue';

export default defineComponent({
    props: {},
    components: {
        CircledQuestionMark,
    },
});
